









































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class Tramites extends Vue {
  private aCategorias: any = null;

  private mounted() {
    document.title =
      "Pagos y Servicios- Ilustre Municipalidad de Juan Fernández";
    this.getServicios();
  }
  private getServicios() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("servicios/general?tipo=servicios")
      .then((res: any) => {
        this.aCategorias = res.data;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
        this.routerGo("Home");
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
}
